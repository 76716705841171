import currency from 'currency.js';
import { USD_SYMBOL } from 'components/TextInput/Currency';

export default function formatCurrency(
  value: number | string,
  options?: any
): string {
  return currency(value, {
    symbol: USD_SYMBOL,
    precision: 0,
    ...options
  }).format();
}

export function formatAmount(value: number | string): string {
  return currency(value, {
    symbol: '',
    precision: 0
  }).format();
}

import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';
import { TextInput } from './TextInput';
import { ICurrencyProps } from './types';

export const USD_SYMBOL = '$ ';
export const THOUSAND_SEPARATOR = ',';

export function Currency(props: ICurrencyProps): React.ReactElement {
  const { onChange, name, value, ...rest } = props;

  const onValueChange = useCallback(
    (change) => {
      onChange && onChange({ target: { name, value: change.floatValue || 0 } });
    },
    [name, onChange]
  );
  return (
    <NumberFormat
      prefix={USD_SYMBOL}
      allowLeadingZeros={true}
      {...(rest as unknown as any)}
      name={name}
      data-testid={name}
      value={value}
      customInput={TextInput}
      fixedDecimalScale={true}
      decimalScale={0}
      allowNegative={false}
      type="text"
      thousandSeparator={THOUSAND_SEPARATOR}
      onValueChange={onValueChange}
    />
  );
}
